import JurorsTable from '../../components/Jurors/JurorsTable';
import { Typography, Box } from '@mui/material';

function JurorsDetails() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        width: 'fit-content'
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2, mt: 5 }}>
        Jurorzy
      </Typography>
      <JurorsTable />
    </Box>
  );
}

export default JurorsDetails;

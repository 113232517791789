import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export default function ButtonAddCategory() {
    const navigate = useNavigate();

    const handeAddJurorClick = () => {
        navigate('/categories/add-category');
    }
    
    return (
        <Fab color="primary" aria-label="add"
        sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
          }}
            onClick={handeAddJurorClick}
        >
            <AddIcon />
        </Fab>
    )
}
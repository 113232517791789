import React from 'react';
import EventInfoBox from '../../components/EventDetails/EventInfoBox';
import { Container, Grid } from '@mui/material';

export default function EventDetails() {
    return (
        <Grid container justifyContent="center" alignItems="flex-start" sx={{ minHeight: '100vh', py: 4 }}>
            <Grid item xs={12}>
                <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                    <EventInfoBox />
                </Container>
            </Grid>
        </Grid>  
    );   
}
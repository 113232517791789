import React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsiveAppBarEvent from '../components/headers/ResponsiveAppBarEvent';

const AuthenticatedLayout = () => (
  <>
    <ResponsiveAppBarEvent />
    <Outlet />
  </>
);

export default AuthenticatedLayout;
import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Button, Backdrop, CircularProgress, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import axios from 'axios';
import { Paths } from '../../Paths';
import { useNavigate } from 'react-router-dom';
import ButtonAddCategory from './ButtonAddCategory';
import UniversalSnackbar from '../app/UniversalSnackbar';

function createData(name, actions) {
  return { name, actions };
}

export default function CategoriesTable() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [openSnackbarDelete, setOpenSnackbarDelete] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCategoryId, setSelectedJurorId] = useState(null);

    const handleEditClick = (categoryId) => {
        navigate(`/categories/edit-category/${categoryId}`)
    };

    const handleOpenDialog = (categoryId) => {
      setSelectedJurorId(categoryId);
      setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };
    
    const handleConfirmDelete = () => {
      const deleteCategory = async () => {
        setIsDialogOpen(false);
        try {
          setLoading(true);
          await axios.delete(`${Paths.serverApi}/api/web-panel/categories/${selectedCategoryId}`);
          await fetchData();
        } catch (error) {
          console.error('Error deleting category:', error);
        } finally {
          setLoading(false);
          setOpenSnackbarDelete(true);
        }
      };
      deleteCategory();
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(`${Paths.serverApi}/api/web-panel/categories/${1}`);
        const data = response.data;

        const processedData = data.map(item => createData(item.category_name, (
          <Box display="flex" gap={2}>
            {/* <Button onClick={() => handleEditClick(item.id)} variant="outlined" color="success">Edytuj</Button> */}
            <Button onClick={() => handleOpenDialog(item.id)} variant="outlined" color="error">Usuń</Button>
          </Box>
        )));
        setCategories(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      }
    };

    useEffect(() => {
        fetchData();
    }, []);

  return (
    <Container sx={{ padding: '20px' }}>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Nazwa kategorii</TableCell>
                <TableCell>Akcje</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {categories.map((row, index) => (
                <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.actions}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        <ButtonAddCategory />
        </TableContainer>
        {loading && (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )}

        <UniversalSnackbar
          open={openSnackbarDelete}
          setOpen={setOpenSnackbarDelete}
          message="Kategoria i wszystkie powiązane kryteria zostały usunięte."
          severity="success"
        />

        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Potwierdzenie usunięcia"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć kategorię i wszystkie powiązane z nią kryteria?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='error'>Anuluj</Button>
            <Button onClick={handleConfirmDelete} autoFocus color='info'>
              Usuń
            </Button>
          </DialogActions>
        </Dialog>

    </Container>
  );
}
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, CardActions, Divider, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SettingsIcon from '@mui/icons-material/Settings';
import PublishIcon from '@mui/icons-material/Publish';
import axios from 'axios';
import { Paths } from '../../Paths';

const EventPage = () => {
  const navigate = useNavigate();
  const [event, setEvent] = useState({
    event_name: '',
    event_place: '',
    event_start_date: '',
    event_desc: ''
  });
  const [publishEnabled, setPublishEnabled] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleAddModelNumberOne = () => {
    navigate('/add-model-number-one');
  };
  
  const handleAddModelNumberTwo = () => {
    navigate('/add-model-number-two');
  };
  
  const handleManageConsents = () => {
    navigate('/consents');
  };

  useEffect(() => {
    const fetchEventData = async () => {
      const eventId = sessionStorage.getItem('eventId');
      const apiUrl = `${Paths.serverApi}/api/events/${eventId}`;

      try {
        const response = await axios.get(apiUrl);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    const checkPublishStatus = async () => {
      const eventId = sessionStorage.getItem('eventId');
      const apiUrl = `${Paths.serverApi}/api/admin/check-members-scores`;
      try {
        const response = await axios.post(apiUrl, { eventId });
        setPublishEnabled(response.data.status === 0);
      } catch (error) {
        console.error('Error checking publish status:', error);
      }
    };

    fetchEventData();
    checkPublishStatus();
  }, []);

  const handlePublishResults = async () => {
    setOpenDialog(false);
    const eventId = sessionStorage.getItem('eventId');
    const apiUrl = `${Paths.serverApi}/api/admin/show-members-scores`;
    try {
      await axios.post(apiUrl, { eventId });
      console.log('Results published successfully');
    } catch (error) {
      console.error('Error publishing results:', error);
    }
  };

  return (
    <Card sx={{ maxWidth: 650, margin: 'auto', mt: 5, boxShadow: 3 }}>
      <CardContent>
        <Typography gutterBottom variant="h4" component="div" sx={{ fontWeight: 'medium' }}>
          {event.event_name}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
          Miejsce: {event.event_place}
        </Typography>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
          Data rozpoczęcia: {event.event_start_date}
        </Typography>
        <Typography variant="body1" color="text.primary">
          Opis: {event.event_desc}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mb: 2 }}>
          <Button startIcon={<ArrowForwardIcon />} size="medium" color="primary" variant="contained" onClick={handleAddModelNumberOne}>
            Dodaj 1 numer modelki
          </Button>
          <Button startIcon={<ArrowForwardIcon />} size="medium" color="primary" variant="contained" onClick={handleAddModelNumberTwo}>
            Dodaj 2 numer modelki
          </Button>
        </Box>
        <Button startIcon={<SettingsIcon />} size="medium" color="secondary" variant="contained" onClick={handleManageConsents}>
          Zarządzaj zgodami wydarzenia
        </Button>
        <Button
          startIcon={<PublishIcon />}
          size="medium"
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
          disabled={!publishEnabled}
          style={{marginTop: '15px'}}
        >
          Opublikuj wyniki dla uczestników
        </Button>
      </CardActions>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Potwierdzenie publikacji wyników"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Czy na pewno chcesz opublikować wyniki dla uczestników?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Anuluj</Button>
          <Button onClick={handlePublishResults} autoFocus>
            Potwierdź
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EventPage;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Card, Button, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import UpdateIcon from '@mui/icons-material/Update';

const fetchEventData = async (eventId) => {
  const response = await axios.post(`https://jurorapp.pl/api/get-event-summary`, {
    eventId: eventId
  });
  return response.data;
};

const CountdownTimer = ({ countdown, visible }) => visible ? (
  <Typography variant="h6" style={{ margin: '10px' }}>
    Następna aktualizacja za: {countdown} sekund
  </Typography>
) : null;

const Category = ({ category }) => (
  <Grid item xs={12} sm={6} md={4} style={{ padding: 8 }}>
    <Card style={{ margin: 8, padding: 16, backgroundColor: '#424242' }}>
      <Typography variant="h5" style={{ color: '#e0e0e0' }}>{category.category_name}</Typography>
      {category.users.map((user, index) => <UserDetails key={user.id} user={user} rank={index + 1} isFirst={index === 0 && user.total_points > 0} />)}
    </Card>
  </Grid>
);

const UserDetails = ({ user, rank, isFirst }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{rank}. {user.name} {user.surname} - <b style={{ fontWeight: 'bold' }}>{user.total_points}</b> {isFirst && <StarIcon style={{ color: 'gold', verticalAlign: 'middle' }} />}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {user.criteria.map((criterion, index) => (
          <Grid item xs={12} key={criterion.criterion_id} style={{ backgroundColor: index % 2 === 0 ? '#616161' : '#757575', color: '#fff' }}>
            <Typography>{criterion.criterion_name}: <b>{criterion.points}</b></Typography>
          </Grid>
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);

const Dashboard = () => {
  const [categories, setCategories] = useState([]);
  const [liveUpdate, setLiveUpdate] = useState(true);
  const [countdown, setCountdown] = useState(30); // Zmienione na 30 sekund

  useEffect(() => {
    const eventId = sessionStorage.getItem('eventId');
    fetchEventData(eventId).then(data => setCategories(data));

    if (liveUpdate) {
      const interval = setInterval(() => {
        fetchEventData(eventId).then(data => {
          setCategories(data);
          setCountdown(30); // Resetowanie odliczania do 30 sekund
        });
      }, 30000); // Co 30 sekund
      return () => clearInterval(interval);
    }
  }, [liveUpdate]);

  useEffect(() => {
    if (liveUpdate) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown > 0 ? prevCountdown - 1 : 30);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [liveUpdate]);

  const handleManualUpdate = () => {
    const eventId = sessionStorage.getItem('eventId');
    fetchEventData(eventId).then(data => {
      setCategories(data);
      setCountdown(30);
    });
  };

  return (
    <Box sx={{ width: '100%', margin: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
        {liveUpdate && <CountdownTimer countdown={countdown} visible={liveUpdate} />}
        <Button startIcon={<AutorenewIcon />} variant="contained" color="primary" onClick={() => setLiveUpdate(!liveUpdate)} style={{ margin: '10px' }}>
          {liveUpdate ? 'Wyłącz aktualizację na żywo' : 'Włącz aktualizację na żywo'}
        </Button>
        <Button startIcon={<UpdateIcon />} variant="contained" color="secondary" onClick={handleManualUpdate} style={{ margin: '10px' }}>
          Aktualizacja ręczna
        </Button>
      </Box>
      <Grid container style={{ width: '100%' }}>
        {categories.map(category => <Category key={category.id} category={category} />)}
      </Grid>
    </Box>
  );
};

export default Dashboard;
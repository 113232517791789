import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Button, Backdrop, CircularProgress, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Import for green checkmark
import CancelIcon from '@mui/icons-material/Cancel'; // Import for red cross
import axios from 'axios';
import { Paths } from '../../Paths';
import ButtonAddMember from './ButtonAddMember';
import UniversalSnackbar from '../app/UniversalSnackbar';

// Updated createData function to include subscription status and model consent
function createData(name, surname, email, phone, subscription, modelConsent, actions) {
  return { name, surname, email, phone, subscription, modelConsent, actions };
}

export default function MembersTable() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const eventId = sessionStorage.getItem('eventId');
    const organizerId = sessionStorage.getItem('userId');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogResetPasswordOpen, setIsDialogResetPasswordOpen] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [openSnackbarDelete, setOpenSnackbarDelete] = useState(false);
    const [openSnackbarResetPassword, setOpenSnackbarResetPassword] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleOpenDialog = (memberId) => {
      setSelectedMemberId(memberId);
      setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    const handleOpenDialogResetPassword = (memberId) => {
      setSelectedMemberId(memberId);
      setIsDialogResetPasswordOpen(true);
    };

    const handleCloseDialogResetPassword = () => {
      setIsDialogResetPasswordOpen(false);
    };

    const handleConfirmResetPassword = () => {
      const authToken = sessionStorage.getItem('token');
      const resetPassword = async () => {
        setIsDialogResetPasswordOpen(false);
        try {
          setLoading(true);
          await axios.post(`${Paths.serverApi}/api/reset-password/${selectedMemberId}`, {}, {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          });
        } catch (error) {
          console.error('Error reset password member:', error);
        } finally {
          setLoading(false);
          setOpenSnackbarResetPassword(true);
        }
      };
      resetPassword();
    };

    const handleConfirmDelete = () => {
      const deleteMember = async () => {
        setIsDialogOpen(false);
        try {
          setLoading(true);
          await axios.delete(`${Paths.serverApi}/api/web-panel/members/${selectedMemberId}`);
          await fetchData();
        } catch (error) {
          console.error('Error deleting member:', error);
        } finally {
          setLoading(false);
          setOpenSnackbarDelete(true);
        }
      };
      deleteMember();
    };

    const allHaveConsent = rows.every(row => row.modelConsent.props.color === "success");

    const handleDownloadAllConsents = () => {
      const event_id = sessionStorage.getItem('eventId');
      const downloadAllConsents = async () => {
          try {
              setLoadingButton(true);
              const response = await axios.post(`${Paths.serverApi}/api/download-consents`, {
                  eventId: event_id,
              }, {
                  responseType: 'blob', // Ważne dla obsługi plików binarnych
              });
  
              // Tworzenie URL do pobrania
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'consents.zip'); // Nazwa pliku
              document.body.appendChild(link);
              link.click();
  
              link.parentNode.removeChild(link); // Czyszczenie po operacji
              window.URL.revokeObjectURL(url); // Zwolnienie zasobów
          } catch (error) {
              console.error('Error downloading consents:', error);
          } finally {
              console.log('Pobrano pomyślnie');
              setLoadingButton(false);
          }
      };
      downloadAllConsents();
  };  

    const fetchData = async () => {
      try {
        const response = await axios.get(`${Paths.serverApi}/api/web-panel/members/${eventId}/${organizerId}`);
        const data = response.data;

        const processedData = data.map(item => createData(
          item.name, item.surname, item.email, item.phone_number,
          item.have_subscription === 1 ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />,
          item.have_uploaded_consent === 1 ? <CheckCircleOutlineIcon color="success" /> : <CancelIcon color="error" />,
          <Box display="flex" gap={2}>
            <Button onClick={() => handleOpenDialogResetPassword(item.id)} variant="outlined" color="info">Resetuj hasło</Button>
            <Button onClick={() => handleOpenDialog(item.id)} variant="outlined" color="error">Usuń</Button>
          </Box>
        ));
        setRows(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching members:', error);
        setLoading(false);
      }
    };

    useEffect(() => {
        fetchData();
    }, [eventId, organizerId]); 

  return (
    <Container sx={{ padding: '20px' }}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button 
            variant="contained" 
            onClick={handleDownloadAllConsents} 
            disabled={!allHaveConsent || loadingButton}
            style={{
              minWidth: '320px', // Adjust according to your needs
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loadingButton ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              'Pobierz wszystkie zgody modelek'
            )}
          </Button>
        </Box>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Imię</TableCell>
                <TableCell>Nazwisko</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Numer telefonu</TableCell>
                <TableCell>Subskrypcja</TableCell> {/* Existing header cell for Subscription */}
                <TableCell>Zgoda modelki</TableCell> {/* New header cell for Model Consent */}
                <TableCell>Akcje</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row, index) => (
                <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.surname}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.subscription}</TableCell> {/* Existing table cell for Subscription */}
                <TableCell>{row.modelConsent}</TableCell> {/* New table cell for Model Consent */}
                <TableCell>{row.actions}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        <ButtonAddMember />
        {loading && (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )}
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Potwierdzenie usunięcia"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć tego członka?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='error'>Anuluj</Button>
            <Button onClick={handleConfirmDelete} autoFocus color='info'>Usuń</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isDialogResetPasswordOpen} onClose={handleCloseDialogResetPassword} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Potwierdzenie resetowania hasła"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz resetować hasło tego członka?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogResetPassword} color='error'>Anuluj</Button>
            <Button onClick={handleConfirmResetPassword} autoFocus color='info'>Resetuj</Button>
          </DialogActions>
        </Dialog>
        
        <UniversalSnackbar open={openSnackbarDelete} setOpen={setOpenSnackbarDelete} message="Uczestnik został usunięty!" severity="success" />
        <UniversalSnackbar open={openSnackbarResetPassword} setOpen={setOpenSnackbarResetPassword} message="Hasło uczestnika zostało zresetowane!" severity="success" />
    </Container>
  );
}
import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../Paths';

function AddCategory() {
  const [categoryName, setCategoryName] = useState('');
  const [criterions, setCriterions] = useState([{ criterion_name: '', criterion_desc: '' }]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const eventId = sessionStorage.getItem('eventId');
  const navigate = useNavigate();

  const handleAddCriterion = () => {
    setCriterions([...criterions, { criterion_name: '', criterion_desc: '' }]);
  };
  
  const handleCriterionChange = (index, e) => {
    const newCriterions = [...criterions];
    newCriterions[index][e.target.name] = e.target.value;
    setCriterions(newCriterions);
  };
  
  const handleRemoveCriterion = (index) => {
    const newCriterions = [...criterions];
    newCriterions.splice(index, 1);
    setCriterions(newCriterions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${Paths.serverApi}/api/web-panel/add-category`, {
        category_name: categoryName,
        event_id: eventId,
        criterions: criterions,
      });
      navigate('/categories/');
    } catch (error) {
      console.error('Wystąpił błąd podczas dodawania kategorii:', error);
      setSnackbar({ open: true, message: 'Nie udało się dodać kategorii. Spróbuj ponownie!', severity: 'error' });
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          width: 'fit-content'
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2, mt: 5 }}>
          Dodawanie kategorii
        </Typography>
      </Box>
      <form onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="categoryName"
          label="Nazwa kategorii"
          name="categoryName"
          autoComplete="categoryName"
          autoFocus
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <Divider sx={{ my: 2 }}>Kryteria</Divider>
        {criterions.map((criterion, index) => (
          <div key={index}>
            <TextField
              margin="normal"
              required
              fullWidth
              id={`criterionName-${index}`}
              label="Nazwa kryterium"
              name="criterion_name"
              value={criterion.criterion_name}
              onChange={(e) => handleCriterionChange(index, e)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`criterionDesc-${index}`}
              label="Opis kryterium"
              name="criterion_desc"
              value={criterion.criterion_desc}
              onChange={(e) => handleCriterionChange(index, e)}
            />
            {criterions.length > 1 && (
              <Button
                onClick={() => handleRemoveCriterion(index)}
                fullWidth
                variant="outlined"
                color='error'
                sx={{ mt: 1, mb: 2 }}
              >
                Usuń kryterium
              </Button>
            )}
          </div>
        ))}
        <Button
          onClick={handleAddCriterion}
          fullWidth
          variant="contained"
          color='secondary'
          sx={{ mt: 3, mb: 2 }}
        >
          Dodaj kryterium
        </Button>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 0 }}
        >
          Dodaj kategorię
        </Button>
        <Button
          onClick={() => navigate('/categories/')}
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Anuluj
        </Button>
      </form>
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}

export default AddCategory;
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export default function ButtonAddEvent() {
    const navigate = useNavigate();

    const handeAddEventClick = () => {
        navigate('/panel/add-event');
    }
    
    return (
        <Fab color="primary" aria-label="add"
        sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
          }}
            onClick={handeAddEventClick}
        >
            <AddIcon />
        </Fab>
    )
}
import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Container, Paper } from '@mui/material';
import axios from 'axios';
import { Paths } from '../../Paths';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // For the file add icon

const AddConsentForm = () => {
  const [consentName, setConsentName] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  let navigate = useNavigate();

  const handleGoBackButton = () => {
    navigate('/consents');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!consentName || !file) {
      setError('Wszystkie pola są wymagane.');
      return;
    }

    if (file.size > 10485760) { // 10MB in bytes
      setError('Maksymalny rozmiar pliku to 10MB.');
      return;
    }

    const eventId = sessionStorage.getItem('eventId'); 
    
    const formData = new FormData();
    formData.append('name', consentName);
    formData.append('consent_file', file);
    formData.append('eventId', eventId);

    const apiUrl = `${Paths.serverApi}/api/event-consents`;
    try {
      await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setError('');
      setConsentName('');
      setFile(null);
      navigate('/consents');
    } catch (error) {
      console.error('Error submitting the form:', error);
      setError('Error submitting the form. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleFormSubmit}
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Dodaj Zgodę
        </Typography>
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        <TextField
          required
          fullWidth
          id="consentName"
          label="Nazwa zgody"
          margin="normal"
          value={consentName}
          onChange={(e) => setConsentName(e.target.value)}
        />
        <Paper variant="outlined" sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          {file ? <Typography>{file.name}</Typography> : <Typography>Kliknij, aby dodać plik</Typography>}
          <Button
            variant="contained"
            component="label"
            startIcon={<AddCircleOutlineIcon />}
          >
            Dodaj plik
            <input
              type="file"
              hidden
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Button>
        </Paper>
        <Box sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'center' }}>
          <Button
            type="submit"
            variant="contained"
          >
            Zapisz
          </Button>
          <Button
            variant="contained"
            onClick={handleGoBackButton}
          >
            Wróć
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddConsentForm;
import CategoriesTable from '../../components/Categories/CategoriesTable';
import { Typography, Box } from '@mui/material';

function CategoriesDetails() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        width: 'fit-content'
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2, mt: 5 }}>
        Kategorie
      </Typography>
      <CategoriesTable />
    </Box>
  );
}

export default CategoriesDetails;

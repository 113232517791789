import JurorsTable from '../../components/Members/MembersTable';
import { Typography, Box } from '@mui/material';

function MembersDetails() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        width: 'fit-content'
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2, mt: 5 }}>
        Uczestnicy
      </Typography>
      <JurorsTable />
    </Box>
  );
}

export default MembersDetails;

import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const UniversalSnackbar = ({ open, setOpen, message, severity, duration = 3000 }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const alertStyles = {
    success: {
      bgcolor: 'lightgreen', // jasne tło dla success
      color: 'green', // ciemniejszy tekst dla kontrastu
    },
    error: {
      bgcolor: 'lightcoral', // jasne tło dla error
      color: 'darkred', // ciemniejszy tekst dla kontrastu
    },
    // Możesz dodać więcej stylów dla innych typów alertów
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} 
      sx={{ width: '100%', ...alertStyles[severity] }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default UniversalSnackbar;

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export default function ButtonAddJuror() {
    const navigate = useNavigate();

    const handeAddMemberClick = () => {
        navigate('/members/add-member');
    }
    
    return (
        <Fab color="primary" aria-label="add"
        sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
          }}
            onClick={handeAddMemberClick}
        >
            <AddIcon />
        </Fab>
    )
}
import React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsiveAppBar from '../components/headers/ResponsiveAppBar';

const AuthenticatedLayout = () => (
  <>
    <ResponsiveAppBar />
    <Outlet />
  </>
);

export default AuthenticatedLayout;
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  // Sprawdzenie, czy użytkownik jest zalogowany
  const isAuthenticated = sessionStorage.getItem('isLoggedIn') === 'true';

  // Jeśli użytkownik jest zalogowany, renderuj dzieci komponentu (Outlet)
  // Jeśli nie, przekieruj do strony logowania
  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
import LoginPage from './layouts/LoginPage/LoginPage';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import darkTheme from './Theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './layouts/LoginPage/ProtectedRoute';
import MainPanel from './layouts/Panel/MainPanel';
import AddEvent from './layouts/Panel/AddEvent';
import AuthenticatedLayout from './headers/AuthenticatedLayout';
import AuthenticatedLayoutEvent from './headers/AuthenticatedLayoutEvent';
import EventDetails from './layouts/EventDetails/EventDeatils';
import JurorsDetails from './layouts/Jurors/JurorsDetails';
import AddJuror from './layouts/Jurors/AddJuror';
import CategoriesDetails from './layouts/Categories/CategoriesDetails';
import AddCategory from './layouts/Categories/AddCategory';
import EditCategory from './layouts/Categories/EditCategory';
import MembersDetails from './layouts/Members/MembersDetails';
import AddMember from './layouts/Members/AddMember';
import AddModelNumberOne from './layouts/EventDetails/AddModelNumberOne';
import AddModelNumberTwo from './layouts/EventDetails/AddModelNumberTwo';
import ConsentsPanel from './layouts/consents/ConsentsPanel';
import AddConsentForm from './layouts/consents/AddConsentForm';
import Scores from './layouts/Scores/Scores';

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
      <Router>
          <Routes>
              <Route path="/" element={<LoginPage/>} />
              <Route element={<ProtectedRoute />}>
                <Route element={<AuthenticatedLayout />}>
                  <Route path="/panel" element={<MainPanel/>} />
                  <Route path="/panel/add-event" element={<AddEvent />} />
                </Route>
                <Route element={<AuthenticatedLayoutEvent />}>
                  {/* Szczegóły wydarzenia */}
                  <Route path="/details/event/*" element={<EventDetails/>} />
                  <Route path="/add-model-number-one" element={<AddModelNumberOne/>} />
                  <Route path="/add-model-number-two" element={<AddModelNumberTwo/>} />

                  {/* Jurorzy */}
                  <Route path="/jurors" element={<JurorsDetails/>} />
                  <Route path="/jurors/add-juror" element={<AddJuror/>} />

                  {/* Kategorie */}
                  <Route path="/categories" element={<CategoriesDetails/>} />
                  <Route path="/categories/add-category" element={<AddCategory/>} />
                  <Route path="/categories/edit-category/:categoryId" element={<EditCategory/>} />

                  {/* Uczestnicy */}
                  <Route path="/members" element={<MembersDetails/>} />
                  <Route path="/members/add-member" element={<AddMember/>} />

                  {/* Zgody */}
                  <Route path="/consents" element={<ConsentsPanel/>} />
                  <Route path="/consents/add-consent" element={<AddConsentForm/>} />

                  {/* Wyniki */}
                  <Route path="/scores" element={<Scores/>} />
                  
                </Route>
              </Route>
          </Routes>
      </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Box } from '@mui/material';
import { Paths } from '../../Paths';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function AddEvent() {
  const [formData, setFormData] = useState({
    event_name: '',
    event_place: '',
    event_start_date: '',
    event_start_time: '',
    event_desc: '',
  });
  const [snackbar, setSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const showSnackbar = (message) => {
    setSnackbar({ open: true, message });
    // Ustaw timer, aby ukryć Snackbar po 3 sekundach
    setTimeout(() => {
        setSnackbar({ open: false, message: '' });
    }, 3000); // 3000 ms = 3 sekundy
}; 

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();
  const organizerId = sessionStorage.getItem('userId');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${Paths.serverApi}/api/web-panel/add-event/${organizerId}`, formData);
      setLoading(false);
      navigate('/panel');
    } catch (error) {
      console.error('Wystąpił błąd podczas wysyłania formularza:', error);
      showSnackbar('Proszę wypełnić wszystkie pola!');
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
          margin="normal"
          required
          fullWidth
          id="event_name"
          label="Nazwa wydarzenia"
          name="event_name"
          autoComplete="event_name"
          autoFocus
          value={formData.event_name}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="event_place"
          label="Miejsce wydarzenia"
          name="event_place"
          autoComplete="event_place"
          value={formData.event_place}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="event_start_date"
          label="Data rozpoczęcia"
          name="event_start_date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="event_start_date"
          value={formData.event_start_date}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="event_start_time"
          label="Godzina rozpoczęcia"
          name="event_start_time"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="event_start_time"
          value={formData.event_start_time}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="event_desc"
          label="Opis wydarzenia"
          name="event_desc"
          autoComplete="event_desc"
          multiline
          rows={4}
          value={formData.event_desc}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 0 }}
        >
          Dodaj wydarzenie
        </Button>
        <Button
          onClick={() => navigate('/panel')}
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Anuluj
        </Button>
      </Box>
      <Snackbar
            open={snackbar.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                severity="warning"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbar.message}
            </Alert>
        </Snackbar>
        {loading && (
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
          >
              <CircularProgress color="inherit" />
          </Backdrop>
        )}
    </Container>
  );
}

export default AddEvent;

import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Button, Backdrop, CircularProgress, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@mui/material';
import axios from 'axios';
import { Paths } from '../../Paths';
import { useNavigate } from 'react-router-dom';
import ButtonAddJuror from './ButtonAddJuror';
import UniversalSnackbar from '../app/UniversalSnackbar';

function createData(name, surname, email, phone, actions) {
  return { name, surname, email, phone, actions };
}

export default function JurorsTable() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const eventId = sessionStorage.getItem('eventId');
    const organizerId = sessionStorage.getItem('userId');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogRestedPasswordOpen, setIsDialogRestedPasswordOpen] = useState(false);
    const [selectedJurorId, setSelectedJurorId] = useState(null);
    const [openSnackbarDelete, setOpenSnackbarDelete] = useState(false);
    const [openSnackbarResetPassword, setOpenSnackbarResetPassword] = useState(false);

    const navigate = useNavigate();

    const handleOpenDialog = (jurorId) => {
      setSelectedJurorId(jurorId);
      setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    const handleOpenDialogResetPassword = (jurorId) => {
      setSelectedJurorId(jurorId);
      setIsDialogRestedPasswordOpen(true);
    }

    const handleCloseDialogResetPassword = () => {
      setIsDialogRestedPasswordOpen(false);
    }

    // Przykładowe akcje
    const handlePreviewClick = (jurorId) => {
        // Logika dla podglądu jurora
        console.log('Podgląd', jurorId);
    };
    
    const handleConfirmResetPassword = () => {
      const authToken = sessionStorage.getItem('token');
      const resetPassword = async () => {
        setIsDialogRestedPasswordOpen(false);
        try {
          setLoading(true);
          await axios.post(`${Paths.serverApi}/api/reset-password/${selectedJurorId}`, {}, {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          });
        } catch (error) {
          console.error('Error reset password member:', error);
        } finally {
          setLoading(false);
          setOpenSnackbarResetPassword(true);
        }
      };
      resetPassword();
    };

    const handleConfirmDelete = () => {
      const deleteJuror = async () => {
        setIsDialogOpen(false);
        try {
          setLoading(true);
          await axios.delete(`${Paths.serverApi}/api/web-panel/jurors/${selectedJurorId}`);
          // Po usunięciu, ponownie załaduj jurorów
          await fetchData(); // fetchData musi być wydzielona lub zdefiniowana w taki sposób, aby była dostępna
        } catch (error) {
          console.error('Error deleting juror:', error);
        } finally {
          setLoading(false);
          setOpenSnackbarDelete(true);
        }
      };
      deleteJuror();
  };
  

    const fetchData = async () => {
      try {
        const response = await axios.get(`${Paths.serverApi}/api/web-panel/jurors/${eventId}/${organizerId}`);
        const data = response.data;

        const processedData = data.map(item => createData(item.name, item.surname, item.email, item.phone_number, (
          <Box display="flex" gap={2}>
            {/* <Button onClick={() => handlePreviewClick(item.id)} variant="outlined" color="success" >Edytuj</Button> */}
            <Button onClick={() => handleOpenDialogResetPassword(item.id)} variant="outlined" color="info">Resetuj hasło</Button>
            <Button onClick={() => handleOpenDialog(item.id)} variant="outlined" color="error">Usuń</Button>
          </Box>
        )));
        setRows(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching jurors:', error);
        setLoading(false);
      }
    };

    useEffect(() => {
        fetchData();
    }, [eventId, organizerId]); 

  return (
    <Container sx={{ padding: '20px' }}>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Imię</TableCell>
                <TableCell>Nazwisko</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Numer telefonu</TableCell>
                <TableCell>Akcje</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row, index) => (
                <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.surname}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.actions}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        <ButtonAddJuror />
        {loading && (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )}
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Potwierdzenie usunięcia"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz usunąć tego jurora?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='error'>Anuluj</Button>
            <Button onClick={handleConfirmDelete} autoFocus color='info'>
              Usuń
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isDialogRestedPasswordOpen}
          onClose={handleCloseDialogResetPassword}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Potwierdzenie usunięcia"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Czy na pewno chcesz resetować hasło tego jurora?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogResetPassword} color='error'>Anuluj</Button>
            <Button onClick={handleConfirmResetPassword} autoFocus color='info'>
              Resetuj
            </Button>
          </DialogActions>
        </Dialog>
        
        <UniversalSnackbar
          open={openSnackbarDelete}
          setOpen={setOpenSnackbarDelete}
          message="Juror został usunięty!"
          severity="success"
        />

        <UniversalSnackbar
          open={openSnackbarResetPassword}
          setOpen={setOpenSnackbarResetPassword}
          message="Hasło jurora zostało zresetowane!"
          severity="success"
        />

    </Container>
  );
}
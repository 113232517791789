import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonAddEvent from './ButtonAddEvent';
import axios from 'axios';
import { Paths } from '../../Paths'
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function createData(name, start_date, city, actions) {
  return { name, start_date, city, actions };
}

export default function EventsTable() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const organizerId = sessionStorage.getItem('userId');
    const navigate = useNavigate();

    const handleOpenEventClick = (eventId) => {
        navigate(`/details/event/${eventId}`);
        sessionStorage.setItem('eventId', eventId);
    }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${Paths.serverApi}/api/web-panel/events/${organizerId}`);
            const data = response.data;
    
            const processedData = data.map(item => createData(item.event_name, item.event_start_date, item.event_place, <Button onClick={() => handleOpenEventClick(item.id)} variant="outlined" color="info">Otwórz</Button>));
            setRows(processedData);
            setLoading(false);
          } catch (error) {
            console.error('Wystąpił błąd podczas pobierania danych:', error);
            setLoading(false);
          }
        };
    
        fetchData();
    }, []); 

  return (
    <Container sx={{ padding: '20px' }}>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Nazwa wydarzenia</TableCell>
                <TableCell align="right">Data rozpoczęcia</TableCell>
                <TableCell align="right">Miejscowość</TableCell>
                <TableCell align="right">Akcje</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.start_date}</TableCell>
                <TableCell align="right">{row.city}</TableCell>
                <TableCell align="right">{row.actions}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        <ButtonAddEvent />
        {loading && (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )}
    </Container>
  );
}
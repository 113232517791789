import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Alert, CircularProgress, Backdrop } from '@mui/material';
import { Paths } from '../../Paths';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    });
    let navigate = useNavigate();

    const showSnackbar = (message) => {
        setSnackbar({ open: true, message });
        // Ustaw timer, aby ukryć Snackbar po 3 sekundach
        setTimeout(() => {
            setSnackbar({ open: false, message: '' });
        }, 3000); // 3000 ms = 3 sekundy
    };    
   
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            setLoading(true);
            const response = await axios.post(`${Paths.serverApi}/api/login`, {
                email,
                password,
            });
            const loggedInUser = response.data;
            setLoading(false);
            sessionStorage.setItem('token', loggedInUser.token);
            sessionStorage.setItem('userId', loggedInUser.userId);
            sessionStorage.setItem('isLoggedIn', true);
            navigate('/panel');
      
          } catch (error) {
            showSnackbar('Błąd logowania');
            setLoading(false);
          }

      };

    return (
        <>
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item>
            <Container component="main" maxWidth="xs">
        <Box
            sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h5">
            Logowanie do systemu
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Nazwa użytkownika"
                name="username"
                autoComplete="username"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Hasło"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Zaloguj się
            </Button>
            </Box>
            {loading && (
               <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Box>
        </Container>
        </Grid>
        </Grid>    
        <Snackbar
            open={snackbar.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                severity="warning"
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbar.message}
            </Alert>
        </Snackbar>
        </>
    );
}

export default LoginPage;

import React, { useEffect, useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Box, Container, TableContainer, Paper, Typography, Backdrop, CircularProgress, Tab } from '@mui/material';
import axios from 'axios';
import { Paths } from '../../Paths';
import { useNavigate } from 'react-router-dom';

const AddModelNumberTwo = () => {
  const [users, setUsers] = useState([]);
  const [modelNumbers, setModelNumbers] = useState({});
  const eventId = sessionStorage.getItem('eventId');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Paths.serverApi}/api/web-panel/adding-models-numbers/${eventId}`);
        const data = response.data;
  
        const initialModelNumbers = {};
        data.forEach(user => {
          // Bezpośrednie przypisanie wartości z danych lub pusty ciąg, jeśli wartość jest null/undefined
          initialModelNumbers[user.id] = user.model_number_2 ?? '';
        });
  
        setUsers(data);
        setModelNumbers(initialModelNumbers);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);  

  const handleModelNumberChange = (userId, value) => {
    setModelNumbers(prevModelNumbers => ({
      ...prevModelNumbers,
      [userId]: Number(value)
    }));
  };

  const handleSaveAll = async () => {
    setLoading(true);
    const modelNumbersArray = Object.entries(modelNumbers).map(([userId, number]) => ({
      userId: Number(userId),
      number: Number(number),
    }));
  
    try {
      await axios.post(`${Paths.serverApi}/api/web-panel/add-models-numbers-two`, { modelNumbers: modelNumbersArray });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAllAndGoBack = async () => {
    setLoading(true);
    const modelNumbersArray = Object.entries(modelNumbers).map(([userId, number]) => ({
      userId: Number(userId),
      number: Number(number),
    }));
  
    try {
      await axios.post(`${Paths.serverApi}/api/web-panel/add-models-numbers-two`, { modelNumbers: modelNumbersArray });
      navigate('/details/event/');
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        width: 'fit-content'
      }}
    >
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2, mt: 5 }}>
        Numerki modeli (2)
      </Typography>
      <Container sx={{ padding: '20px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Imię i nazwisko</TableCell>
                <TableCell>Adres e-mail</TableCell>
                <TableCell>Drugi numer modelki</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id}>
                  <TableCell>{user.name} {user.surname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <TextField
                      value={modelNumbers[user.id] || ''}
                      onChange={(e) => handleModelNumberChange(user.id, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button onClick={handleSaveAll} sx={{ marginTop: 2 }} color='success' variant="outlined">Zapisz numerki</Button>
        <Button onClick={handleSaveAllAndGoBack} sx={{ marginTop: 2, marginLeft: 2 }} color='info' variant="outlined">Zapisz numerki i wróć do menu</Button>
        <Button onClick={() => navigate('/details/event/')} sx={{ marginTop: 2, marginLeft: 2 }} color='error' variant="outlined">Wróć bez zapisywania</Button>
      </Container>
      {loading && (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )}
    </Box>
  );
};

export default AddModelNumberTwo;
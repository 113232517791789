import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Fab,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../Paths';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';

const ConsentsPanel = () => {
  let navigate = useNavigate();
  const [consents, setConsents] = useState([]); // State to hold consents from API
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [error, setError] = useState(null); // State to handle errors
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedConsentId, setSelectedConsentId] = useState(null);
  const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileToReplaceId, setFileToReplaceId] = useState(null);

  useEffect(() => {
    const fetchConsents = async () => {
      setIsLoading(true);
      try {
        const eventId = sessionStorage.getItem('eventId');
        const apiUrl = `${Paths.serverApi}/api/event-consents/${eventId}`; // Construct the API URL
        const response = await axios.get(apiUrl); // Send GET request with eventId
        setConsents(response.data); // Update consents state with API response
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConsents();
  }, []);

  const handleClickButtonAdd = () => {
    navigate('/consents/add-consent');
  };

  const handleOpenDialog = (consentId) => {
    setOpenDialog(true);
    setSelectedConsentId(consentId);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedConsentId(null);
  };

  const handleClickButtonDelete = async () => {
    try {
      await axios.post(`${Paths.serverApi}/api/event-consent/${selectedConsentId}`);
      setConsents(consents.filter(consent => consent.id !== selectedConsentId));
      handleCloseDialog(); // Zamknij dialog po usunięciu
    } catch (error) {
      console.error('Error deleting the file:', error);
    }
  };  
   
  const handleClickButtonDownload = async (fileId) => {
    try {
      // Twoje żądanie pozostaje takie samo
      const response = await fetch(`${Paths.serverApi}/api/download-event-consent/${fileId}`, {
        method: 'GET',
        // Nagłówki w razie potrzeby
      });

      if (!response.ok) throw new Error('Network response was not ok');
      
      const blob = await response.blob();
      const contentDisposition = response.headers.get('X-Filename');
      const filename = contentDisposition || `download-${fileId}`;
      const downloadUrl = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename); // Używa nazwy pliku z odpowiedzi
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
    }
};

const handleOpenReplaceDialog = (fileId) => {
    setOpenReplaceDialog(true);
    setFileToReplaceId(fileId);
  };
  
  const handleCloseReplaceDialog = () => {
    setOpenReplaceDialog(false);
    setSelectedFile(null); // Opcjonalnie wyczyść wybrany plik
  };
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleReplaceFile = async () => {
    if (!selectedFile) {
      alert("Proszę wybrać plik do zamiany.");
      return;
    }
  
    const formData = new FormData();
    formData.append('new_file', selectedFile);
  
    try {
      const response = await axios.post(`${Paths.serverApi}/api/event-consents/replace/${fileToReplaceId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        alert('Plik został pomyślnie zastąpiony.');
        handleCloseReplaceDialog();
        // Możesz tutaj odświeżyć listę plików, jeśli to konieczne
      } else {
        alert('Wystąpił błąd podczas zastępowania pliku.');
      }
    } catch (error) {
      console.error('Error replacing the file:', error);
      alert('Wystąpił błąd podczas zastępowania pliku.');
    }
  };

  return (
    <>
        <Box sx={{ position: 'relative', p: 3 }}>
      {isLoading && <p>Wczytywanie zgód...</p>}
      {error && <p>Error fetching consents: {error.message}</p>}
      {/* Render the table only when consents are available */}
      {consents.length === 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
            <Typography variant="h6" color="textSecondary">
            Nie ma jeszcze żadnych zgód.
            </Typography>
        </Box>
        )}

      {consents.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nazwa zgody</TableCell>
                <TableCell align="right">Akcje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consents.map((consent) => (
                <TableRow
                  key={consent.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {consent.consent_name}
                  </TableCell>
                  <TableCell align="right">
                    <Button startIcon={<ChangeCircleIcon />} variant="contained" color="primary"
                        onClick={() => handleOpenReplaceDialog(consent.id)}
                    >
                      Zamień plik
                    </Button>
                    <Button startIcon={<DownloadIcon />} variant="contained" color="secondary" sx={{ mx: 1 }}
                        onClick={() => handleClickButtonDownload(consent.id)}
                    >
                      Pobierz
                    </Button>
                    <Button startIcon={<DeleteIcon />} variant="contained" color="error"
                        onClick={() => handleOpenDialog(consent.id)}
                    >
                      Usuń
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* Floating Action Button for adding new consents */}
      <Fab color="primary" onClick={handleClickButtonAdd} aria-label="add" sx={{ position: 'fixed', bottom: 15, right: 15 }}>
        <AddIcon />
      </Fab>
    </Box>
    <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Potwierdzenie usunięcia</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Czy napewno chcesz usunąć ten plik?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>Anuluj</Button>
            <Button onClick={handleClickButtonDelete} autoFocus>
            Usuń
            </Button>
        </DialogActions>
    </Dialog>
    <Dialog open={openReplaceDialog} onClose={handleCloseReplaceDialog}>
        <DialogTitle>Zamień plik</DialogTitle>
        <DialogContent>
            <Input type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseReplaceDialog}>Anuluj</Button>
            <Button onClick={handleReplaceFile}>Zamień plik</Button>
        </DialogActions>
    </Dialog>
    </>
  );
};

export default ConsentsPanel;
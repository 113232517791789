import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  Divider
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

// Załóżmy, że Paths zawiera podstawowe ścieżki URL do API
import { Paths } from '../../Paths';

function EditCategory() {
  const [categoryName, setCategoryName] = useState('');
  const [criterions, setCriterions] = useState([{ criterion_name: '', criterion_desc: '' }]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const { categoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      try {
        // Uzupełnij URL według konfiguracji API
        const response = await axios.get(`${Paths.serverApi}/api/web-panel/categories/single/${categoryId}`);
        setCategoryName(response.data.category.category_name);
        setCriterions(response.data.criterions || []);
      } catch (error) {
        console.error('Błąd przy wczytywaniu danych kategorii:', error);
        setSnackbar({ open: true, message: 'Nie udało się wczytać danych kategorii.', severity: 'error' });
      }
      setLoading(false);
    };

    fetchCategoryData();
  }, [categoryId]);

  const handleAddCriterion = () => {
    setCriterions([...criterions, { criterion_name: '', criterion_desc: '' }]);
  };
  
  const handleCriterionChange = (index, e) => {
    const newCriterions = [...criterions];
    newCriterions[index][e.target.name] = e.target.value;
    setCriterions(newCriterions);
  };
  
  const handleRemoveCriterion = (index) => {
    const newCriterions = [...criterions];
    newCriterions.splice(index, 1);
    setCriterions(newCriterions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Uzupełnij URL według konfiguracji API
      await axios.put(`${Paths.serverApi}/api/web-panel/categories/${categoryId}`, {
        category_name: categoryName,
        criterions: criterions,
      });
      navigate('/categories');
    } catch (error) {
      console.error('Wystąpił błąd podczas aktualizacji kategorii:', error);
      setSnackbar({ open: true, message: 'Nie udało się zaktualizować kategorii. Spróbuj ponownie!', severity: 'error' });
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="categoryName"
          label="Nazwa kategorii"
          name="categoryName"
          autoComplete="categoryName"
          autoFocus
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <Divider sx={{ my: 2 }}>Kryteria</Divider>
        {criterions.map((criterion, index) => (
          <div key={index}>
            <TextField
              margin="normal"
              required
              fullWidth
              id={`criterionName-${index}`}
              label="Nazwa kryterium"
              name="criterion_name"
              value={criterion.criterion_name}
              onChange={(e) => handleCriterionChange(index, e)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`criterionDesc-${index}`}
              label="Opis kryterium"
              name="criterion_desc"
              value={criterion.criterion_desc}
              onChange={(e) => handleCriterionChange(index, e)}
            />
            {criterions.length > 1 && (
              <Button
                onClick={() => handleRemoveCriterion(index)}
                fullWidth
                variant="outlined"
                color="error"
                sx={{ mt: 1, mb: 2 }}
              >
                Usuń kryterium
              </Button>
            )}
          </div>
        ))}
        <Button
          onClick={handleAddCriterion}
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 3, mb: 2 }}
        >
          Dodaj kryterium
        </Button>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 0 }}
        >
          Zaktualizuj kategorię
        </Button>
        <Button
          onClick={() => navigate('/categories/')}
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Anuluj
        </Button>
      </form>
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}

export default EditCategory;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Box, Snackbar, Alert, Backdrop, CircularProgress, Select, InputLabel, OutlinedInput, Chip, MenuItem, useTheme, Typography } from '@mui/material';
import { Paths } from '../../Paths';
import { useNavigate } from 'react-router-dom';

function AddMember() {
  const eventId = sessionStorage.getItem('eventId');
  const [categories, setCategories] = useState([]);
  const [downloadedCategories, setDownloadedCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone_number: '',
    event_id: eventId,
    rank_name: 'member'
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const showSnackbar = (message) => {
    setSnackbar({ open: true, message });
    setTimeout(() => {
        setSnackbar({ open: false, message: '' });
    }, 3000);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    // Zapisuje ID wybranych kategorii zamiast nazw
    setCategories(typeof value === 'string' ? value.split(',') : value);
    // Aktualizuje formData, aby zawierała ID wybranych kategorii
    setFormData({ ...formData, categories: typeof value === 'string' ? value.split(',') : value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Paths.serverApi}/api/web-panel/categories/${eventId}`);
        const data = response.data;
        setDownloadedCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchData();
  }, []);
    

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${Paths.serverApi}/api/web-panel/add-member`, formData);
      setLoading(false);
      navigate('/members');
    } catch (error) {
      console.error('Wystąpił błąd podczas wysyłania formularza:', error);
      showSnackbar('Proszę wypełnić wszystkie wymagane pola!');
      setLoading(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <Container component="main" maxWidth="xs">
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          width: 'fit-content'
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2, mt: 5 }}>
          Dodawanie uczestnika
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Imię"
          name="name"
          autoComplete="name"
          autoFocus
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="surname"
          label="Nazwisko"
          name="surname"
          autoComplete="surname"
          value={formData.surname}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Adres e-mail"
          name="email"
          type="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="phone_number"
          label="Numer telefonu"
          name="phone_number"
          type="tel"
          autoComplete="phone"
          value={formData.phone_number}
          onChange={handleChange}
        />
        
        <InputLabel id="demo-multiple-chip-label">Kategorie</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          fullWidth
          value={categories}
          onChange={handleCategoryChange}
          input={<OutlinedInput id="select-multiple-chip" label="Kategorie" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => {
                // Znajdź kategorię po ID, aby użyć jej nazwy
                const category = downloadedCategories.find((category) => category.id === value);
                return <Chip key={value} label={category ? category.category_name : 'Nieznana kategoria'} />;
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {downloadedCategories.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={getStyles(item.category_name, item.category_name, theme)}
            >
              {item.category_name}
            </MenuItem>
          ))}
        </Select>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 0 }}
        >
          Dodaj uczestnika
        </Button>
        <Button
          onClick={() => navigate('/members')}
          fullWidth
          variant="outlined"
          color='error'
          sx={{ mt: 3, mb: 2 }}
        >
          Anuluj
        </Button>
      </Box>
      <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert
                severity="error"
                variant="outlined"
                sx={{ width: '100%' }}
            >
                {snackbar.message}
            </Alert>
        </Snackbar>
        {loading && (
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
          >
              <CircularProgress color="inherit" />
          </Backdrop>
        )}
    </Container>
  );
}

export default AddMember;